<po-page-default p-title="{{user}}!">

    <!-- <h3 class="po-mb-5">{{ literals.str0054 }}</h3> -->

    <!-- <po-container class="container-home po-row"> -->

        <!-- <div class="po-font-subtitle po-xl-6 po-lg-6 po-md-6 po-sm-6 po-mt-4" >{{ literals.str0055 }}</div> -->
        <img src="./assets/icons/homeRotinas.png" alt="imagem nossas rotinas" >

    <!-- </po-container> -->


</po-page-default>
