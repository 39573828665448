/* src/app/core/home/home.component.css */
.container-home {
  background-color: #E5E5E5;
}
img {
  margin-top: 7%;
  width: 400px;
  height: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.box {
  height: 80px;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
/*# sourceMappingURL=home.component.css.map */
