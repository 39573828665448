import { Component, Input, OnInit } from '@angular/core';

import { LiteralsWords } from './../../i18n/literals.model';
import { LiteralsService } from './../../shared/services/literals-service';
import { PoLanguage, PoI18nService } from '@po-ui/ng-components';
import { ActivatedRoute } from '@angular/router';
import { MenuService } from '../../features/purchase-requests/shared/services/menu.service';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';

@Component({
    selector: 'tgc-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: false
})
export class HomeComponent implements OnInit{

  literals: LiteralsWords = this.literalsService.getLiterals();

  user: string = '';

  constructor(private literalsService: LiteralsService,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
    private temporaryStorageService: TemporaryStorageService
    ) {
      this.user = (`${this.literals?.str0097} ${sessionStorage.getItem('user')}` || 'User');
  }

  ngOnInit(): void {
    this.menuService.loading(false); //-- Desativa loading dos resolvers, é ativado no login.component

    this.activatedRoute?.data?.forEach(element => {
      if (element?.fieldsSC1?.items) {
        this.temporaryStorageService.setValue('fieldsPropertiesSC1', element.fieldsSC1);
      }
      if (element?.fieldsDHU?.items) {
        this.temporaryStorageService.setValue('fieldsPropertiesDHU', element.fieldsDHU);
      }
      if (element?.fieldsSC8) {
        this.temporaryStorageService.setValue('structSC8', element.fieldsSC8);
      }
      if (element?.structSC1) {
        this.temporaryStorageService.setValue('structSC1', element.structSC1);
      }
      if (element?.structDHU) {
        this.temporaryStorageService.setValue('structDHU', element.structDHU);
      }
      if (element?.structSA2) {
        this.temporaryStorageService.setValue('structSA2', element.structSA2);
      }
      if (element?.structWF7) {
        this.temporaryStorageService.setValue('structWF7', element.structWF7);
      }
      if (element?.structSC7) {
        this.temporaryStorageService.setValue('structSC7', element.structSC7);
      }
    });
  }

}
